import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faCalendarAlt,
  faVideo,
  faFilm,
  faEye,
  faCamera,
  faQuestionCircle,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { faNewspaper, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import UrlCollect from "../../../common/url-collect";
import * as statementAction from "../../../redux/store/statement/statement.store";
import * as configuration from "../../../utils/configuration";
import OtherLink from "./other-link.view";

export default function MenuRightView(props) {
  const [serviceModels, setServiceModels] = useState(null);

  useEffect(() => {
    statementAction
      .GetSerivceLink()
      .then(
        (res) =>
          res &&
          res.content &&
          res.content.linkGroup &&
          setServiceModels(res.content.linkGroup)
      )
      .catch((err) => {});
  }, []);

  return (
    <div className="col-12 col-md-12 col-lg-4 pl-2 pr-2">
      <div className="header-result">
        <div className="title">
          <Link className="text-uppercase" to={UrlCollect.SyntheticPlanning}>
            BẢN ĐỒ TỔNG HỢP QUY HOẠCH
          </Link>
        </div>
      </div>

      <div className="content-result">
        <div className="item item-map">
          <div className="row">
            <div className="col-12">
              <img
                src={require("../../../assets/image/qhsdd.jpg")}
                alt="Map"
                className="img-fluid w-100 mb-2"
              />
            </div>
          </div>
        </div>

        {serviceModels &&
          serviceModels.map((items, index) =>
            items.displayType === "IMAGE" ? (
              <div className="item mb-1">
                
              </div>
            ) : (
              <div>
                <div className="header-result">
                  <div className="title">
                    <a
                      className="text-uppercase"
                      data-toggle="collapse"
                      href={"#Parent" + index}
                      role="button"
                      aria-expanded="false"
                      aria-controls="department"
                    >
                      <FontAwesomeIcon
                        icon={faAngleDoubleDown}
                        className="mr-2"
                      />
                      {items.name}
                    </a>
                  </div>
                </div>
                {items.linkService &&
                  items.linkService.length > 0 &&
                  items.linkService.map((itemsChild) => (
                    <div className="collapse" id={"Parent" + index}>
                      <div className="content-result pt-2 pb-2 mt-0">
                        <ul className="m-0">
                          <li>
                            <a
                              href={itemsChild.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {itemsChild.name}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
              </div>
            )
          )}

          <OtherLink />

      </div>
    </div>
  );
}
