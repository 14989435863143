import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Drawer from "react-bottom-drawer";

import Collapsible from "react-collapsible";
import * as MapToolsPanelsStore from "../../../../redux/store/open-layer/map-tools-panels.store";
import MapReport from "../report/report";

function Economic(props) {
    const [isVisible, setIsVisible] = React.useState(false);
    const [headerTable, setHeaderTable] = useState<any>();

    useEffect(() => {
        setHeaderTable(props.headerTable);
        if(props.mapReportStatus && props.mapReportStatus.isOpen == true){
            setIsVisible(true);
        }
    }, [props.headerTable,props.mapReportStatus]);

    const openDrawer = (type) => {
        let reportUrl = '/api/cms/Charts/NumberInboundVisitorInPeriodChart';
        let defaultChart = 'NumberInboundVisitorInPeriodChart';
        let chkEconomicCurrent: any = document.getElementById('chkEconomicCurrent');
        let chkEconomicPlan: any = document.getElementById('chkEconomicPlan');
        if(type == 2) {
            chkEconomicCurrent.checked = false;
            chkEconomicPlan.checked = true;
            reportUrl = '/api/cms/Charts/IdentityInLDChart';
            defaultChart = 'IdentityInLDChart';
        }else{
            chkEconomicCurrent.checked = true;
            chkEconomicPlan.checked = false;
        }

        props.SetMapReportStatus({
            'type':type,
            'isOpen':true,
            'reportUrl':reportUrl,
            'defaultChart':defaultChart
        });
        setIsVisible(true);
    }

    const closeDrawer = () => {
        setIsVisible(false);
        props.SetMapReportStatus({'type':0,'isOpen':false});
    }
    return(
        <div>
            <summary>
                <img
                    id={"level2-arrow-down0"}
                    className="position-absolute level2-arrow-down level2-right-position"
                    src={require("../../../../assets/icon/arrow-down-2.png")}
                    alt="Arrow Down"
                />
                <img
                    id={"level2-arrow-up0"}
                    className="position-absolute level2-arrow-up level2-right-position"
                    src={require("../../../../assets/icon/arrow-up-2.png")}
                    alt="Arrow Right"
                />
                <Collapsible className="left-menu-level-2"
                             trigger="Kinh tế - Xã hội"
                             onTriggerOpening   = {() => {
                                 props.setMenuIconL2(0,'open');
                             }}
                             onTriggerClosing    = {() => {
                                 props.setMenuIconL2(0,'close');
                             }}
                >
                    <div>
                        <div onClick={()=>openDrawer(1)} className="left-menu-folder-box">
                            <a>
                                <input id="chkEconomicCurrent" type="checkbox" /> Hiện trạng Kinh tế - Xã hội
                            </a>
                        </div>
                        <div onClick={()=>openDrawer(2)} className="left-menu-folder-box">
                            <a><input  id="chkEconomicPlan"  type="checkbox" /> Quy hoạch Kinh tế - Xã hội</a>
                        </div>
                    </div>
                </Collapsible>
            </summary>
            <div className="report-container-box">
                <Drawer
                    duration={250}
                    hideScrollbars={true}
                    onClose={closeDrawer}
                    isVisible={isVisible}
                >
                    <MapReport
                        closeReport={()=>{
                            closeDrawer()
                        }}
                    />
                </Drawer>
            </div>
        </div>
    );

}

const mapStateToProps = (state) => ({
    SetMapReportStatus: MapToolsPanelsStore.SetMapReportStatus,
    mapReportStatus: state.mapToolsPanel.mapReport,
    headerTable: state.mapData.headerTable,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            SetMapReportStatus: MapToolsPanelsStore.SetMapReportStatus,
        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Economic);