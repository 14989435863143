import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faVideo,
  faFilm,
  faEye,
  faCamera,
  faQuestionCircle,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { faNewspaper, faEnvelope } from "@fortawesome/free-regular-svg-icons";

export default function OtherLinkView() {
  return (
      <div className="menu-item-link">
        <ul className="list-unstyled">
          <li>
            <a href="http://www.lamdong.gov.vn/Pages/default.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
              Cổng thông tin điện tử
            </a>
          </li>
          <li>
            <a href="https://baolamdong.com.vn/truyen-hinh" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faVideo} />
                </span>
              Truyền hình Lâm Đồng
            </a>
          </li>
          <li>
            <a href="https://baolamdong.com.vn/truyen-hinh" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faVideo} />
                </span>
              Phát thanh Lâm Đồng
            </a>
          </li>
          <li>
            <a href="https://baolamdong.com.vn/truyen-hinh" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faNewspaper} />
                </span>
              Báo Lâm Đồng
            </a>
          </li>
          <li>
            <a href="https://dulich.lamdong.gov.vn/Trang/trangchu.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faEye} />
                </span>
              Du lịch Lâm Đồng
            </a>
          </li>
          <li>
            <a href="https://www.lamdong.gov.vn/Trang/lich-cong-tac.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
              Lịch công tác
            </a>
          </li>
          <li>
            <a href="https://www.lamdong.gov.vn/Trang/hoi-dap.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              Hỏi đáp trực tuyến
            </a>
          </li>
          <li>
            <a href="https://www.lamdong.gov.vn/Trang/danh-sach-van-ban-phap-quy.aspx" target="_blank" >
                <span>
                  <FontAwesomeIcon icon={faBook} />
                </span>
              Văn bản pháp quy
            </a>
          </li>
          <li>
            <a href="https://www.lamdong.gov.vn/Trang/van-ban-chi-dao.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faBook} />
                </span>
              VB chỉ đạo điều hành
            </a>
          </li>
          <li>
            <a href="https://docs.google.com/forms/d/1dVRyq7E3H9c_BwG1_wOzUAFWsao_sbcn1s0L7TFrLIQ/viewform?edit_requested=true" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              Hòm thư góp ý
            </a>
          </li>
        </ul>
      </div>
  );
}
