import React, { Fragment } from "react";
import "./planning-map-view.scss";
import ViLanguages from "../../languages/vi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import GuideUseMap from "./guide-use-map/guide-use-map.view";
import TopRightPanelView from "./top-right-panel/top-right-panel.view";
import MapToolsPanel from "./map-tools-panel/map-tools-panel.view.jsx";
import LeftMenuView from "./left-menu/left-menu.view";
import RenderFilterInfomationPopup from "./filter-infomation/filter-infomation.view.jsx";
import SelectCurrentLayerView from "./select-current-layer/select-current-layer.view";
import OpenlayersMapView from "../openlayers-map/openlayers-map.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";
import PlanningMapViewModel from "../../models/planning-map-view/planning-map-view.model";
import MapNote from "./map-note/note";
import UserGuide from "./left-menu/guide/guide";
import Preview from "./left-menu/preview/preview";
import {
  ExtractSelectedBasemap,
  ExtractCenterArray,
  ExtractExtentArray,
  ExtractListLayerArray,
  ExtracLayerListGroup,
  ExtracAllLayersArrays,
  ExtractBasemapList,
} from "../../common/extract-information-from-map-data";

const LanguageCollects = ViLanguages;

class PlanningMapView extends React.Component<
  PlanningMapViewModel.PlanningMapViewProps,
  PlanningMapViewModel.PlanningMapViewState
  > {
  constructor(props: PlanningMapViewModel.PlanningMapViewProps) {
    super(props);
    this.state = {
      mapPlanning: null,
      isLeftNavbarHide: false,
      modalHeightStyle: 0,
      isShowMapToolsPanel: false,
      isShowFilterInfomationPopup: false,
      displaySearchLocationBar: false,
      planningModel: null
    };
    this.openFilterInfomationPopup = this.openFilterInfomationPopup.bind(this);
    this.closeFilterInfomationPopup = this.closeFilterInfomationPopup.bind(
      this
    );
  }

  componentDidMount() {
    this.setState({
      modalHeightStyle: window.innerHeight - 100,
    });
    this.props.GetMapDetail(this.props.match.params.id);
  }

  handleClickIconLeftNavbar(id: number) {
    this.handleClickToggleLeftNavBar();
    const buttonElement = document.querySelector(
      `button[aria-controls="${id}"]`
    ) as HTMLElement;
    if (buttonElement && buttonElement.classList.contains("collapsed"))
      buttonElement.click();
  }

  handleClickToggleLeftNavBar() {
    this.setState({
      isLeftNavbarHide: !this.state.isLeftNavbarHide,
    });
    if (!this.state.isLeftNavbarHide) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("hide-left-navbar");
    } else
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("hide-left-navbar");
  }

  openFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: true,
    });
  }

  closeFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: false,
    });
  }

  manualCloseMaptoolsFilterInfomationPopup = () => {
    this.setState({ isShowFilterInfomationPopup: false });
  };
  setPlanningMap = (_planningModel: any) => {
    this.setState({planningModel: _planningModel});
  }

  render() {
    const isLeftNavbarHide = this.state.isLeftNavbarHide;
    // const isShowMapToolsPanel = this.state.isShowMapToolsPanel;
    return (
      <div className="planning-map-view-container position-relative">
        {/* top right panel*/}
        <TopRightPanelView
          baseMapList={ExtractBasemapList(this.props.mapData)}
          planningId={this.props.match.params.id}
          setPlanningModelInMap={this.setPlanningMap}
        />

        {/* map tool panel control */}

        <div className="position-absolute map-tools-container">
          <div className="position-relative">
            <RenderFilterInfomationPopup listGroupLayer={ExtracLayerListGroup(this.props.mapData)} listLayer={ExtractListLayerArray(this.props.mapData)} />
          </div>
          <MapToolsPanel
            openFIlterPopup={this.openFilterInfomationPopup}
            map={this.state.mapPlanning}
          />
        </div>
        {/* open layer view port */}

        {this.props.hasLoadedMapData && this.props.mapData.name && (
          <OpenlayersMapView
            defaultBaseMapUrl={ExtractSelectedBasemap(this.props.mapData)}
            defaultCenter={ExtractCenterArray(this.props.mapData)}
            defaultZoom={this.props.mapData.zoom}
            projection={this.props.mapData.projection}
            maxZoom={this.props.mapData.max_zoom}
            minZoom={this.props.mapData.min_zoom}
            extent={ExtractExtentArray(this.props.mapData)}
            listLayer={ExtractListLayerArray(this.props.mapData)}
            planningModel ={this.state.planningModel}
            listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
          />
        )}
        {this.props.hasLoadedMapData && !this.props.mapData.name && (
          <div
            className="modal d-block"
            id="modalNotHaveDataToDisplay"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Dữ liệu không có sẵn để hiển thị
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      document
                        .getElementById("modalNotHaveDataToDisplay")
                        ?.classList.remove("d-block")
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  Lỗi: Bản đồ của dự án chưa được khởi tạo, vui lòng liên hệ với
                  SỞ KẾ HOẠCH ĐẦU TƯ TỈNH LÂM ĐỒNG
                </div>
                <div className="modal-footer text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      document
                        .getElementById("modalNotHaveDataToDisplay")
                        ?.classList.remove("d-block")
                    }
                  >
                    Đóng
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* left nav bar */}
        <div className="left-custom-navbar position-absolute">
          {this.props.hasLoadedMapData && this.props.mapData.name && (
            <Fragment>
              <LeftMenuView
                planningId={this.props.match.params.id}
                listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
                isLeftNavbarHide={isLeftNavbarHide}
                toggleStateIsLeftNavbarHide={() =>
                  this.setState({
                    isLeftNavbarHide: !this.state.isLeftNavbarHide,
                  })
                }
              />
            </Fragment>
          )}
        </div>
        <UserGuide
            modalHeightStyle = {this.state.modalHeightStyle}
        />
        <Preview
            modalHeightStyle = {this.state.modalHeightStyle}
        />
        <MapNote />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  hasLoadedMapData: state.mapData.hasLoadingData,
  mapData: state.mapData.mapData,
  planningRelationShips: state.mapData.planningRelationShips,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetMapDetail: MapDataStore.GetMapDetailByPlanningId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PlanningMapView);
