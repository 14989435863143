/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as appActions from "../../core/app.store";
import * as introAction from "../../redux/store/introduce/introduce.store";

import "./planning-intro.scss";
import "@geedmo/yamm/src/yamm.scss";
import {useHistory} from "react-router-dom";

function PlanningIntroView(props) {
  let history = useHistory();
  const { showLoading } = props;
  const [planningIntro,setPlanningIntroduce] = useState({});

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      getPlanIntro(),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const getPlanIntro = () => {
      return new Promise((resolve,reject) =>{
          introAction.GetDetailIntroduce('planning-introduce').then(
              (res) => {
                setPlanningIntroduce(res && res.content.result);
                resolve(res);
              },
              (err) => {
                reject(err);
              }
          );
      });
  }

  const donwloadDB = () => {
      history.push('/ban-do-quy-hoach/1');
  }

  return (
    <div>
      <div className="container">
        <div className="planning-announcement mt-2 mb-4">
          <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="content-result">
                      <div className="item">
                          <div className="row">
                              <div className="col-12">
                                  <img
                                      src={require("../../assets/image/qhsdd.jpg")}
                                      alt="Map"
                                      className="img-fluid w-100 mb-2 mb-md-0"
                                  />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-12">
                                  <div className="map-title" >QUY HOẠCH ĐÔ THỊ LÂM ĐỒNG TỚI NĂM 2030</div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-12">
                                  <div className="map-search-entry">
                                      <div className="map-entry-access-db">
                                          <span>Tra cứu, tìm kiếm, trích xuất thông tin trực tuyến, và cơ sở dữ liệu quy hoạch</span>
                                          <br /><br />
                                          <a href="/ban-do-quy-hoach/1">
                                              <button type="button" className="btn btn-danger">Truy cập CSDL</button>
                                          </a>
                                      </div>
                                      <div className="map-entry-download-db">
                                          <span>Tìm kiếm, tải về cơ sở dữ liệu quy hoạch</span>
                                          <br />
                                          <span>(Yêu cầu quyền truy cập)</span>
                                          <br /><br />
                                          <button type="button" onClick={donwloadDB} className="btn btn-danger">Tải CSDL</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            <div id="planning-intro-content" className="col-12 col-sm-12 col-md-12 col-lg-8 mt-2">
              <div className="plan-intro-title">{planningIntro.title}</div>
              <div className="plan-intro-content">
                <p className="new-desc" dangerouslySetInnerHTML={{ __html: planningIntro.content }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanningIntroView);
